function validateRequest () {
	const date = document.querySelector('.datepicker-input').value
	const timeslots = document.querySelectorAll('.checkbox-timeslot');
	let validDate = isValidDate(date);
	let validTimeslot = isValidTimeslot(timeslots);
	let error = document.querySelector('.date-error');
    console.log(retrieve('selected'));

	if(validDate && validTimeslot) {
		error.classList.remove('--active-error');
		translateSteps(100);
		let dateTimePrice  = getInputs();
		fillBreadcrumb(2, dateTimePrice, {});
		document.querySelector('.date-breadcrumb-js').dataset.date = date;
	} else {
		error.classList.add('--active-error');
	}
}

function isValidDate(d) {
    return !isNaN(Date.parse(d));
}

function isValidTimeslot(timeslots) {
	let checkedTimeslots = Array
		.from(timeslots)
		.filter(function (timeslot) {
			return timeslot.checked;
        });

	return checkedTimeslots.length > 0;
}

function getInputs() {
	let dateTime = getCheckedInputs();
	let price = calculatePrice();
	let priceCurrency = 'â¬' + price + ' incl. VAT';
	dateTime.splice(1, 0, " - ");
	let guests = document.querySelector('input[name=guest-counter]').value;
	guests += (guests > 1) ? ' guests' : ' guest';
	let date = document.querySelector('.datepicker-input').value;
	date = moment(date).format('DD-MM-YYYY');
	return [date, ' â ',  dateTime, '<br />', priceCurrency,'<br />', guests];
}

function calculatePrice(){
    var selected = retrieve('selected');
    var resources = retrieve('resources');

	var pricePerPerson = resources[selected.id].Booking_Engine_Price__c;
    var code = document.querySelector('input[name=promoCode]').value;
    var guests = parseInt(document.querySelector('input[name=guest-counter]').value);
    var nrOfTimeslots = selected.selectedTimeslots.length;

    var resourcePrice = nrOfTimeslots === 1 ? 57.14: 96.55;
    var total = resourcePrice * guests;

    return code === 'Bettertogether' ? 0 : total.toFixed(2);
}
